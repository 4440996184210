<template>
  <div class="flex flex-col min-h-screen bg-image-login relative">
    <div class="flex flex-col flex-grow items-center justify-between lg:h-full">
      <header class="lg:container py-5 flex justify-between items-center">
        <div class="flex flex-col items-start">
          <h4 class="text-white">
            {{ $t('Login.Devoted Studios') | uppercase }}
          </h4>
<!--          <span class="text-xs text-gray-400">{{ $t('Login.Create Your Own Supergroup') }}</span>-->
        </div>
        <div>
          <router-link v-if="showLogin" to="/">
            <FormButton size="small" class="text-white">
              {{ $t('Login.Login') }}
            </FormButton>
          </router-link>
        </div>
      </header>
      <div class="lg:container flex flex-col justify-center items-center">
        <div class="flex-grow flex flex-col justify-center items-center">
          <transition name="fade">
            <router-view />
          </transition>
        </div>
      </div>
      <footer />
    </div>
  </div>
</template>

<script>
import FormButton from '@/components/Form/FormButton.vue';

export default {
  name: 'AuthLayout',
  components: {
    FormButton,
  },
  data() {
    return {
    };
  },
  computed: {
    showLogin() {
      return [
        'Auth.ForgotPassword',
        'Auth.SetPassword',
        'ResetPassword',
      ].includes(this.$route.name);
    },
  },
};
</script>
